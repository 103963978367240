import { configureStore } from "@reduxjs/toolkit";
// import searchReducer from "./features/search/searchSlice";
import searchReducer from "./features/search/searchSlice1";
import exercisesReducer from "./features/exercises/exercisesSlice";
import newsletterReducer from "./features/newsletter/newsletterSlice";
import quizReducer from "./features/quiz/quizSlice";
import userReducer from "./features/user/userSlice";

export const store = configureStore({
  reducer: {
    search: searchReducer,
    exercises: exercisesReducer,
    newsletter: newsletterReducer,
    quiz: quizReducer,
    user: userReducer,
  },
});
