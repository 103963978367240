import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import { navigate } from "gatsby";
import { loginWithEmailAndPassword } from "../../../utils/firebase";

export const fetchQuestions = createAsyncThunk(
  "quiz/fetchQuestions",
  async () => {
    let data = JSON.stringify({
      query:
        "query getAnonymoysQuizes { quizzes { id name quizQuestions { id order question question_type quizQuestionAnswers { id answer } } } }",
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://montessori-graphql-server-27e6d567583f.herokuapp.com/graphql",
      headers: {
        "Content-Type": "application/json",
        "x-monti-website":
          "6kqJaku3GHyXWuzAguwbEgw88KXf9Xjnhqwdq987dwqldkmdq9wd80dqwdlwqkdmqwd98w7dqwd",
      },
      data: data,
    };

    let response = axios

      .request(config)
      .then((response) => {
        if (response.data) {
          let questionsArr = response.data.data.quizzes.find(
            (el) => el.id === 2
          );
          return questionsArr.quizQuestions;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  }
);
export const createChildAnonymousQuiz = createAsyncThunk(
  "quiz/createChildAnonymousQuiz",
  async () => {
    let data = JSON.stringify({
      query:
        "mutation ($input: ChildAnonymousQuizInput!) { createChildAnonymousQuiz(input: $input) { id user_id user{email} child_id } }",
      variables: {
        input: {
          quiz_id: 2,
          completed: false,
        },
      },
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://montessori-graphql-server-27e6d567583f.herokuapp.com/graphql",
      headers: {
        "Content-Type": "application/json",
        "x-monti-website":
          "6kqJaku3GHyXWuzAguwbEgw88KXf9Xjnhqwdq987dwqldkmdq9wd80dqwdlwqkdmqwd98w7dqwd",
      },
      data: data,
    };
    let response2 = axios
      .request(config)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return response2;
  }
);

export const createQuizAnswer = createAsyncThunk(
  "quiz/createQuizAnswer",
  async (props) => {
    let data = JSON.stringify({
      query:
        "mutation ($input: QuizAnswerInput!) { createQuizAnswer(input: $input) { question_id child_quiz_id } }",
      variables: {
        input: {
          user_id: Number(props.startedQuiz.user_id),
          quiz_id: 2,
          question_id: Number(props.selectedAnswer.questionId),
          question_type: props.selectedAnswer.questionType,
          child_quiz_id: Number(props.startedQuiz.quiz_id),
          answer: props.selectedAnswer.answer,
          quiz_question_answer_ids: [Number(props.selectedAnswer.answerId)],
        },
      },
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://montessori-graphql-server-27e6d567583f.herokuapp.com/graphql",
      headers: {
        "Content-Type": "application/json",
        "x-monti-website":
          "6kqJaku3GHyXWuzAguwbEgw88KXf9Xjnhqwdq987dwqldkmdq9wd80dqwdlwqkdmqwd98w7dqwd",
      },
      data: data,
    };
    let response2 = axios
      .request(config)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return response2;
  }
);

export const updateChildQuizUser = createAsyncThunk(
  "quiz/updateChildQuizUser",
  async (props) => {
    let age = dayjs().diff(dayjs(props.formData.date), "month");
    let data = JSON.stringify({
      query:
        "mutation ($input: ChildQuizUserInput!) { updateChildQuizUser(input: $input) { id email displayName } }",
      variables: {
        input: {
          email: props.startedQuiz.user_email,
          new_email: props.formData.email,
          name: props.formData.name,
          age: age,
          completed: true,
          password: props.formData.password,
        },
      },
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://montessori-graphql-server-27e6d567583f.herokuapp.com/graphql",
      headers: {
        "Content-Type": "application/json",
        "x-monti-website":
          "6kqJaku3GHyXWuzAguwbEgw88KXf9Xjnhqwdq987dwqldkmdq9wd80dqwdlwqkdmqwd98w7dqwd",
      },
      data: data,
    };
    let response2 = axios
      .request(config)
      .then((response) => {
        loginWithEmailAndPassword(
          props.formData.email,
          props.formData.password
        );
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return response2;
  }
);

export const getQuizProgress = createAsyncThunk(
  "quiz/getQuizProgress",
  async (props) => {
    console.log(props, "props");
    let data = JSON.stringify({
      query:
        "query getAnonymousChildQuiz($childQuizId: Int!) { childQuiz(id: $childQuizId) { id user_id child_id quiz_id completed quizAnswers { id quiz_id user_id answer question_id question_type quizQuestionAnswers { id answer answerSkillValues { id value skill { id name } } quizQuestion { id question order } } } } }",
      variables: {
        childQuizId: Number(props.quiz_id),
      },
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://montessori-graphql-server-27e6d567583f.herokuapp.com/graphql",
      headers: {
        "Content-Type": "application/json",
        "x-monti-website":
          "6kqJaku3GHyXWuzAguwbEgw88KXf9Xjnhqwdq987dwqldkmdq9wd80dqwdlwqkdmqwd98w7dqwd",
      },
      data: data,
    };
    let response2 = axios
      .request(config)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return response2;
  }
);

const initialState = {
  questions: [],
  currentQuestion: 0,
  selectedAnswer: null,
  finished: false,
  startedQuiz: null,
  answeredQuestions: [],
  // error: null,
  error: null,
  loginUser: false,
};

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setLoginUser: (state, { payload }) => {
      state.loginUser = payload;
    },
    setCurrentQuestion: (state, { payload }) => {
      state.currentQuestion = payload;
    },
    toggleAnswer: (state, { payload }) => {
      if (state.selectedAnswer?.answerId === payload.answerId) {
        state.selectedAnswer = null;
      } else {
        state.selectedAnswer = payload;
      }
    },
    resetSelectedAnswer: (state) => {
      state.selectedAnswer = null;
    },
    //  on page reload get data from cookies and set it to state.startedQuiz
    setStartedQuiz: (state) => {
      let userIdCookie = document.cookie
        .split(";")
        .find((el) => el.includes("user_id"));
      let quizIdCookie = document.cookie
        .split(";")
        .find((el) => el.includes("quiz_id"));
      let childIdCookie = document.cookie
        .split(";")
        .find((el) => el.includes("child_id"));
      let userEmailCookie = document.cookie
        .split(";")
        .find((el) => el.includes("user_email"));
      if (userIdCookie && quizIdCookie && childIdCookie && userEmailCookie) {
        state.startedQuiz = {
          user_id: userIdCookie.split("=")[1],
          quiz_id: quizIdCookie.split("=")[1],
          child_id: childIdCookie.split("=")[1],
          user_email: userEmailCookie.split("=")[1],
        };
      }
    },
    finishQuiz: (state) => {
      state.finished = true;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // get quiz questions and answers from server
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.questions = action.payload.sort((a, b) => a.order - b.order);
      state.error = null;
    });
    builder.addCase(fetchQuestions.rejected, (state, action) => {
      console.log(action.error);
      state.error = {
        type: "fetchQuestions",
        message:
          "Нещо се обърка. Моля опитайте отново да попълните въпросника по-късно.",
      };
    });
    builder.addCase(fetchQuestions.pending, (state, action) => {
      // console.log("pending");
    });

    // create anonymous quiz for child
    builder.addCase(createChildAnonymousQuiz.fulfilled, (state, action) => {
      if (action.payload.data) {
        const d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();

        document.cookie =
          `user_id=${action.payload.data.createChildAnonymousQuiz.user_id};` +
          expires;
        document.cookie =
          `quiz_id=${action.payload.data.createChildAnonymousQuiz.id};` +
          expires;
        document.cookie =
          `child_id=${action.payload.data.createChildAnonymousQuiz.child_id};` +
          expires;
        document.cookie =
          `user_email=${action.payload.data.createChildAnonymousQuiz.user.email};` +
          expires;
        state.startedQuiz = {
          user_id: action.payload.data.createChildAnonymousQuiz.user_id,
          quiz_id: action.payload.data.createChildAnonymousQuiz.id,
          child_id: action.payload.data.createChildAnonymousQuiz.child_id,
          user_email: action.payload.data.createChildAnonymousQuiz.user.email,
        };
        state.error = null;
      }
    });
    builder.addCase(createChildAnonymousQuiz.rejected, (state, action) => {
      console.log(action.error);
      state.error = {
        type: "createChildAnonymousQuiz",
        message:
          "Нещо се обърка. Моля опитайте отново да попълните въпросника по-късно.",
      };
    });
    builder.addCase(createChildAnonymousQuiz.pending, (state, action) => {
      // console.log("pending");
    });

    // send question answer to server
    builder.addCase(createQuizAnswer.fulfilled, (state, action) => {
      // console.log(action, "createQuizAnswer tuk");
      state.error = null;
    });
    builder.addCase(createQuizAnswer.rejected, (state, action) => {
      console.log(action.error);
      state.error = {
        type: "createQuizAnswer",
        message:
          "Нещо се обърка. Моля опитайте отново да отговорите на въпроса.",
      };
    });
    builder.addCase(createQuizAnswer.pending, (state, action) => {
      // console.log("pending");
    });

    // update child quiz user with user data
    builder.addCase(updateChildQuizUser.fulfilled, (state, action) => {
      state.loginUser = true;
      state.answeredQuestions = [];
      state.startedQuiz = null;
      state.currentQuestion = 0;
      state.selectedAnswer = null;
      state.finished = false;
      document.cookie = "user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie = "quiz_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie = "child_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie = "user_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      state.error = null;
      navigate("/");
    });
    builder.addCase(updateChildQuizUser.rejected, (state, action) => {
      console.log(action.error);
      state.error = {
        type: "updateChildQuizUser",
        message: "Нещо се обърка. Моля опитайте отново да изпратите формата.",
      };
    });
    builder.addCase(updateChildQuizUser.pending, (state, action) => {
      // console.log("pending");
    });

    // get quiz progress from server
    builder.addCase(getQuizProgress.fulfilled, (state, action) => {
      state.answeredQuestions =
        action?.payload?.data?.childQuiz?.quizAnswers?.map(
          (el) => el.question_id
        );
      state.error = null;
    });
    builder.addCase(getQuizProgress.rejected, (state, action) => {
      console.log(action.error);
      state.error = {
        type: "getQuizProgress",
        message:
          "Нещо се обърка. Моля опитайте отново да попълните въпросника по-късно.",
      };
    });
    builder.addCase(getQuizProgress.pending, (state, action) => {
      // console.log("pending");
    });
  },
});

export const {
  setCurrentQuestion,
  selectUnselectAnswer,
  finishQuiz,
  startQuiz,
  toggleAnswer,
  resetSelectedAnswer,
  setStartedQuiz,
  resetError,
  setLoginUser,
} = quizSlice.actions;
export default quizSlice.reducer;
