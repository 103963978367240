import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "axios";

const firebaseConfig = {
  apiKey: "AIzaSyALnGZxolgm6uUJN8DQXS5cl1aCNrUKhaU",
  authDomain: "montessori-assistant.firebaseapp.com",
  projectId: "montessori-assistant",
  storageBucket: "montessori-assistant.appspot.com",
  messagingSenderId: "556903584356",
  appId: "1:556903584356:web:b8231af6b981aa4d694571",
  measurementId: "G-Q2RMKBB1PG",
};

if (!firebase?.apps?.length) {
  firebase.initializeApp(firebaseConfig);
}
let user = null;
export const registerWithEmailAndPassword = async (email, password, name) => {
  try {
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    user = userCredential.user;
    if (user) {
      const update = {
        displayName: name,
      };
      await userCredential.user.updateProfile(update);
      const url =
        "https://montessori-graphql-server-27e6d567583f.herokuapp.com/graphql";
      await user.getIdToken().then(async (token) => {
        const response = await axios({
          method: "post",
          url: url,
          headers: {
            "content-Type": "application/json",
            authorization: `Bearer ${token} ${user?.uid}`,
            role: "mobile",
          },
          data: {
            query: `query {
                userByUid(uid:  "${user?.uid}") {
                    id
                }
            }`,
          },
        });
      });
    }
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const loginWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    user = userCredential.user;
    if (user) {
      let data = JSON.stringify({
        email: user.email,
        uid: user.uid,
        refreshToken: user.refreshToken,
        displayName: user.displayName,
      });
      const d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie =
      `firebase_user=${data};` +
      expires;
      document.cookie = "email_firebase" + "=; Max-Age=-99999999;";
      document.cookie = "password_firebase" + "=; Max-Age=-99999999;";
    }
    return user;
  } catch (error) {
    throw error;
  }
};
